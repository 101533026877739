.phone-container-signupPage{
    width: 100%;
    min-width: 300px;
    height: clamp(75px, 14vmin, 96px);
}

.arrow{
    /* position: absolute; */
    right: 15px;
    top: 40%;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-top: 11px solid #000;
    border-right: 10px solid transparent;
    z-index: 10;
   
}

.list-item{
    list-style: none;
    margin-bottom: 0.8em;
    cursor: pointer;
    margin-left: 10px;
    color: var(--fg-color);
    align-self: center;
}

.list-item-container{
    margin-top:15px;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    padding: 0px 20px;
}

.list-item-container img{
    margin-bottom: 15px;
}

.dropdown-header{
    position: relative;
    margin-bottom: 0.8em;
    padding: 0 18px;
    background: var(--bg-alt3-color);
    width: 100%;
    height: clamp(40px, 7vmin, 60px);
    box-sizing: border-box;
    border-radius: 13px;
    cursor: pointer;
    border: 1px solid var(--main-color);
}

.dropdown-header span{
    /* position: absolute; */
    top: 20%;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    font-family: AppFont;
    color: var(--fg-alt2-color);
}

.dropdown-openned{
    background-color: var(--fg-color);
}

.has-value{
    color: var(--fg-color) !important;
}

.has-value-openned{
    color: var(--fg-color);
}

.wrapper{
    height: clamp(40px, 12vmin, 60px);
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--bg-alt3-color);
}
.wrapper:hover{
    background: var(--bg-alt3-color);
}

.dropdown-container{
    position: relative;
    min-width: 300px;
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
}

.dropdown-container .up-text{
    font-size: 24px;
    color: var(--fg-color);
    font-family: AppFont;
    margin-bottom: 0.25rem;
}

@media (max-width: 760px) {
    .dropdown-container .up-text{
        font-size: 20px;
    }

    .dropdown-header span{
        font-size: 16px;
    }
}

.dropdown-list{
    width: 100%;
    padding: 0px 0px;
    margin: 0;
    font-size: clamp(0.7rem, 2vmin, 1.4rem);
    font-weight: 500;
    cursor: default;
    z-index: 2;
}

.dropdown-container .hide{
    position: absolute;
    margin-top: 10px;
    height: 200px;
    width: 100%;
    overflow: hidden;
}

.scroll-container{
    position: absolute;
    width: 100%;
    height: 200px;
    background: var(--bg-color);
    border: 2px solid #e5e5e5;
    box-sizing: border-box;
    color: #ACB6C5;
    border-radius: 15px;
    overflow: hidden;
    animation: growDown 0.3s forwards; 
    z-index: 2;
}

@keyframes growDown {
    
    100% {
      transform: translateY(0px);
    }
}

.arrow-opened{
    border-top: 11px solid var(--main-color);
}

.already-container-signupPage{
    position: relative;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    min-width: 300px;
    align-self: center;
    width: 90%;
    margin-bottom: 20px;
    z-index: 0;
}

.confirmation-txt{
    height: 29px;
    left: 0.35%;
    right: 0%;
    top: calc(50% - 29px/2 - 77px);

    font-family: AppFont;
    font-size: 20px;
    line-height: 29px;

    /* identical to box height, or 145% */
    text-align: center;
    letter-spacing: -0.48px;
    color: var(--fg-alt4-color);
    cursor: default;
}

.track-vertical {
    top: 2px;
    bottom: 2px;
    right: 10px;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.111);
}

.track-vertical .thumb-vertical {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.8);
}

.continue-btn-container-signupPage{
    min-height: 40px !important;
    width: 100%;
    align-self: center;
    height: clamp(40px, 7vmin, 60px);
}


@media (max-width: 992px) {
    .main{
        height: 75%;
    }

    .right{
        justify-content: center !important;
    }

    .buttons-container{
        max-width: 600px;
    }
}