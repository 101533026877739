.personalInfo-container{
    min-height: 655px;
    width: 100%;
}

@media (max-width: 1370px) {
    .personalInfo-container{
        width: calc(100% - 45px);
    }
}

@media (max-width: 914px) {
    .personalInfo-container{
        width: 100%;
    }
}

.personalInfo-container .inputs-container{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 570px;
    margin-top: 20px;
    padding: 30px;
    border-radius: 15px;
    background-color: var(--bg-color);
}

.personalInfo-container .inputs-container .double-input-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.personalInfo-container .inputs-container .double-input-container .left-input{
    width: 48%;
    height: clamp(75px, 14vmin, 96px);
}

.personalInfo-container .inputs-container .double-input-container .right-input{
    height: clamp(75px, 14vmin, 96px);
    width: 48%;
}

.personalInfo-container .inputs-container .btn-container{
    /* position: absolute; */
    width: 200px;
    height: 45px;
    bottom: 40px;
}

.personalInfo-container .inputs-container .conutry-street-container{
    display: flex;
    justify-content: space-between;
    align-items: top;
    max-height: 95px;
}

.personalInfo-container .inputs-container .conutry-street-container .country{
    width: 48%;
    height: clamp(75px, 14vmin, 96px);
}

.personalInfo-container .inputs-container .conutry-street-container .street{
    width: 48%;
    height: clamp(75px, 14vmin, 96px);
}

.personalInfo-container .inputs-container .change-language-container{
    margin-top: -10px;
    width: 100%;
}

@media (max-width: 769px) {
    .personalInfo-container .inputs-container .change-language-container{
        width: 100%;
    }
}

.personalInfo-container .inputs-container .continue-btn{
    height: clamp(40px, 8vmin, 72px);
    width: 60%;
    min-width: 200px;
    margin: 0 auto;
    margin-top: 10px;
}

.change-picture-container{
    width: calc(100%);
    min-height: 420px;
    background-color: var(--bg-color);
    padding: 30px;
    border-radius: 15px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.preview{
    font-family: AppFont;
    font-size: 20px;
    text-align: center;
}

@media (max-width: 1370px) {
    .change-picture-container{
        width: calc(100% - 45px);
    }
}

@media (max-width: 914px) {
    .change-picture-container{
        width: 100%;
    }
}

@media (max-width: 620px) {
    .change-picture-container{
        flex-direction: column;
    }

    .preview{
        margin-top: 20px;
    }
}

.page-title{
    font-family: AppFont;
    font-size: 24px;
    line-height: 22px;
    letter-spacing: -0.576px;
    color: var(--fg-color);
}


@media (max-width: 768px) {
    .personalInfo-container .inputs-container .double-input-container{
        flex-direction: column;
        justify-content: unset;
    }

    .personalInfo-container .inputs-container .double-input-container .left-input{
        width: 100%;
    }

    .personalInfo-container .inputs-container .double-input-container .right-input{
        width: 100%;
    }

    .personalInfo-container .inputs-container{
        min-height: 838px;
    }
}