.gift-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 530px;
    background: var(--bg-color);
    border-radius: 15px;
    padding: 30px;
}

@media (max-width: 1370px) {
    .gift-container{
        width: calc(100% - 45px);
    }
}   

@media (max-width: 914px) {
    .gift-container{
        width: 100%;
    }
}

.gift-container .left-side{
    display: flex;
    justify-content: center;
    width: 48%;
    min-width: 320px;
}

.gift-container .left-side .card-container{
    width: 320px;
    position: relative;
}

.gift-container .right-side{
    display: flex;
    flex-direction: column;
    width: 48%;
    align-items: flex-end;
    min-height: 100px;
    min-width: 320px;
}

.gift-container .top-order-btns{
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 280px;
    margin-top: 20px;
}

.gift-container .btn-container{
    display: flex;
    position: absolute;
    justify-content: space-around;
    align-items: center;
    bottom: 10px;
    left: 30px;
    padding: 0 10px;
    height: 35px;
    background-color: var(--main-color);
    border-radius: 10px;
    cursor: pointer;
}

.gift-container .btn-container span{
    height: 26px;
    font-family: AppFontBold;
}

.gift-container .benefit-container{
    display: flex;
    align-self: flex-end;
    justify-content: center;
    width: max(300px, 80%);
    min-height: 50px;
    margin: 15px 0;
    border-radius: 10px;
    cursor: pointer;
}

@media (max-width: 750px) {
    .gift-container .benefit-container{
        align-self: center;
    }
}

.gift-container .benefit-container img{
    height: 50px;
    margin: auto 0;
}

.gift-container .benefit-container:hover{
    background: var(--bg-alt3-color);
}

.gift-container .benefit-container .text-area{
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 100%;
    border-bottom: 1px solid var(--bg-alt3-color);
}

.gift-container .benefit-container .text-area span{
    font-family: AppFont;
    font-size: 1.1rem;
}

@media (max-width: 750px){
    .gift-container{
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .gift-container .right-side{
        margin-top: 30px;
        width: 100%;
    }
}