.topup-container{
    background-color: var(--bg-color);
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
    min-height: 300px;
    width: calc(100%);
    padding: 30px !important;
}

@media (max-width: 1370px) {
    .topup-container{
        width: calc(100% - 45px);
    }
}


.topup-container .card-container{
    position: relative;
    display: flex;
    justify-content: center;
    width: 48%;
    min-width: 300px;
}

.topup-container .card-container .mobile-container{
    position: relative;
    width: 320px;
    height: 204px;
    margin: auto; 
}

.topup-container .card-container img{ 
    width: 320px;
    height: 204px;;
}

.topup-container .card-container .card-holder-name{
    position: absolute;
    max-width: 278px;
    top: 155px;
    left: 5%;
    color: var(--fg-alt-color);
    font-family: AppFont;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: default;
    background-color: rgba(0, 0, 0, 0.5);
}

.topup-container .card-container .card-number{
    position: absolute;
    top: 125px;
    left: 5%;
    color: var(--fg-alt-color);
    font-family: AppFont;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: default;
    background-color: rgba(0, 0, 0, 0.5);
}

.topup-container .inputs-container{
    display: flex;
    flex-direction: column;
    width: 48%;
}

.topup-container .inputs-container .cardNo-ccv-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: clamp(75px, 14vmin, 96px);
}

.topup-container .inputs-container .cardNo-ccv-container .expiration-date-container{
    width: calc(50% - 10px);
    height: 100%;
}

.topup-container .inputs-container .cardNo-ccv-container .ccv{
    width: calc(50% - 10px);
    height: 100%;
}

.topup-container .inputs-container .btn-container{
    width: 150px;
    height: 40px;
}

@media (max-width: 992px) {
    .topup-container{
        flex-direction: column;
        align-items: center
    }

    .topup-container .inputs-container{
        width: max(50%, 300px);
    }

    .topup-container .inputs-container .btn-container{
        align-self: center;
    }
}

@media (max-width: 914px) {
    .topup-container{
        width: 100%;
    }
}