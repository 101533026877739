.pin-check-container{
    height: 100vh;
    min-height: 500px;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../Images/Heads/full\ head.png");
}

.pin-check-container .continue-btn{
    width: 300px;
    height: 45px;
}

.pin-check-container .switch-text{
    margin-bottom: 20px;
}

.pin-check-container .switch-text span{
    font-size: 0.9rem;
    font-family: AppFont;
    cursor: default;
}

.pin-check-container .switch-text .logout{
    cursor: pointer;
    margin-left: 2px;
    color: var(--main-color);
    text-decoration: underline;
}