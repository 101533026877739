.title{
    font-family: AppFont;
    font-size: 24px;
    line-height: 22px;
    /* identical to box height, or 92% */
    letter-spacing: -0.576px;   
    color: var(--fg-color);
}
.success-message{
    color: #a0cf67;
    font-family: AppFontBold;
    font-size: 1.1rem;
}

.logo_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;
    margin-bottom: 36px;
    min-height: 109px;
    min-width: 150px;
    left: 10%;
    top: 4%;
    cursor: default;
}

.logo_container .logo {
    position: relative;
    left: 0%;
    width: clamp(80px, 10vw, 120px);
    height: clamp(80px, 10vw, 120px);
}

.logo_container span {
    align-self: center;
    left: clamp(10px, 25%, 200px);
    right: 0%;
    top: 25%;
    font-family: AppFontBoldExtra;
    font-size: clamp(60px, 7vw, 80px);
    line-height: 112%;
    /* identical to box height, or 112% */
    letter-spacing: 1.25517px;
    color: var(--fg-alt5-color);
}

@media (max-width: 370px) {
    .logo_container span{
        font-size: 50px;
    }
}

.already-txt{    
    position: relative;
    font-family: AppFont;
    font-size: 20px;
    line-height: 19px;
    /* identical to box height, or 119% */
    text-decoration-line: underline;
    color: var(--fg-color);
    cursor: pointer;
    z-index: -9;
    margin: 0 auto;
}

@media (max-width: 760px) {   
    .already-txt{    
        font-size: 16px;
    }
}
.title-txt-reuse{
    position: relative;
    font-family: AppFontBold;
    font-size: clamp(32px, 4vw, 40px);
    line-height: clamp(32px, 4vw, 40px);
    /* identical to box height, or 46% */
    letter-spacing: -1.152px;
    color: var(--fg-color);
    cursor: default;
}

.message-txt-reuse{
    position: relative;
    font-family: AppFont;
    font-size: clamp(16px, 2vw, 20px);
    line-height: 29px;
    /* identical to box height, or 145% */
    letter-spacing: -0.48px;
    color: #ACB6C5;
    cursor: default;
    margin-top: 10px;
}


.accordion{
    width: 100%;
}

.accordion .content{
    position: relative;
    margin: 10px 0px;
    border: 1px solid var(--bg-alt3-color);
    border-radius: 15px;
}

.accordion .content .question{
    position: relative;
    display: flex;
    align-self: center;
    justify-content: space-between;
    justify-self: center;
    padding: 10px 20px;    
    padding-right: 35px;
    cursor: pointer;
    border-radius: 10px;
    background-color:var(--bg-alt3-color);
    font-family: AppFont;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height, or 119% */
    color: #030303;
}

.accordion .content .question img{
    position: absolute;
    right: 10px;
    top: calc(50% - 7.5px);
    transition: 0.4s;
    transform: rotate(180deg);
}

.accordion .content .question .rotate-img{
    transform: rotate(270deg);
}

.accordion .content .answer{
    position: relative;
    height: 0px;
    padding: 0px 20px;
    overflow: hidden;
    transition: 0.2s;
    overflow-y: auto;

    font-family: AppFont;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    color: #ACB6C5;
}

@keyframes grow1 {
    0%{height: 0px;}
    100%{height: 100%;}
}

@keyframes grow2 {
    0%{transform:translateY(0%);}
    100%{transform: translateY(100%);}
}


.accordion .content .show{
    height: auto;
    padding: 10px 20px;
}

.helper-container{
    position: relative;
    width: 100%;
    height: 0;
}

.helper-container .chat-container{
    position: relative;
    top: 5px;
    left: 20px;
    width: 100%;
    visibility: hidden;
}

.helper-container .chat-container .text-container{
    position: relative;
    text-align: center;
    background: var(--bg-alt3-color);
    margin-left: 10px;
    min-height: 80px;
    max-width: min(350px, 100%);
    left:10px;
    top:-47px;
    border: 1px solid black;
    padding: 3px;
    border-radius: 10px;
}

.helper-container .chat-container .text-container span{
    position: relative;
    top: 2px;
}

.helper-container img:hover + .chat-container{
    visibility: visible;
    z-index: 1;
}

.helper-container .chat-container .arrow1{
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-top: 15px solid #000;
    border-right: 15px solid transparent;
    transform: rotate(90deg);
}

.notification{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    margin: 16.5px 0px;
}

.notification .bubble{
    min-width: 6px;
    min-height: 6px;
    border-radius: 1000px;
    background: #FF3B30;
}

.notification .icon-container{
    display: flex;
    min-width: 56px;
    min-height: 56px;
    margin-left: 10px;
    background: var(--bg-alt3-color);
    border-radius: 10px;;
}

.notification .icon-container img{
    position: relative;
    margin: auto;
}

.notification .text-container{
    height: 20px;
    width: auto;
    overflow: hidden;
}

.notification .text-container .text{ 
    font-family: AppFont;
    font-size: 16px !important;
    line-height: 19px !important;
    word-wrap:unset ;
    /* identical to box height, or 119% */
    color: var(--fg-alt5-color) !important;
    margin-top: 0px !important;
    cursor: pointer;
}

.notification .close-icon{
    cursor: pointer;
}

.notification .separetor{
    position: absolute;
    height: 0px;
    width: 100%;
    border: 1px solid #EDF3FC;
    bottom: -7.5px;
}

.image-right{
    position: absolute;
    left: 637px;
    z-index: -10;
}

.transaction-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 38px;
    width: 100%;
    margin-bottom: 33px;
}

.transaction-container .image-container{
    position: relative;
    left: 0px;
    width: 36px;
    height: 37px;
}

.transaction-container .image-container .person-image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    overflow: hidden;
}

.transaction-container .image-container .type-image{
    position: absolute;
    right: 0;
    border-radius: 1000px;
    bottom: 0;
}

.transaction-container .details-container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: 100px;
    height: 100%;
    margin-left: 10px;
    cursor: default;
}

.transaction-container .details-container .name{
    font-family: AppFontBold;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height, or 119% */
    color: var(--fg-alt4-color);
}

.transaction-container .details-container .date{
    font-family: AppFont;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #ACB6C5;
}

.transaction-container .sum-container{
    position: relative;
    min-width: 80px;
    height: auto;
    right: 0px;
    overflow-x: visible;
}

.transaction-container .sum-container span{
    position: absolute;
    font-family: AppFontBold;
    font-size: 16px;
    line-height: 19px;
    right: 0px;
    /* identical to box height, or 119% */
    color: var(--fg-alt4-color);
}

.transaction-histroy-separator{
    position: relative;
    width: 100%;
    height: 1px;
    bottom: 16.5px;
    background-color: #EDF3FC;
}

.link-dashboard-container{
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.link-dashboard-container .activate-popup{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    padding: 20px 40px;
    transform: translate(-50%, -50%);
    width: 1200px;
    max-width: 90%;
    min-height: 500px;
    border-radius: 15px;
    background-color: var(--bg-color);
    z-index: 10;
}

.link-dashboard-container .activate-popup .close-icon{
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 28px;
    cursor: pointer;
}

.link-dashboard-container .activate-popup .title{
    font-size: 28px;
    font-family: AppFont;
    text-align: center;
}

.link-dashboard-container .activate-popup img{
    width: 320px;
}

.link-dashboard-container .activate-popup .separetor{
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: auto 0;
}

@media (max-width: 820px) {
    .link-dashboard-container .activate-popup .separetor{
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 50px;
    }

    .link-dashboard-container .activate-popup img{
        margin-bottom: 10px;
    }
}

.link-dashboard-container .info-popup{
    position: fixed;
    display: flex;
    bottom: 20px;
    right: 20px;
    visibility: hidden;
    width: 300px;
    max-width: calc(100vw - 40px);
    min-height: 60px;
    border-radius: 13px;
    background-color: #a0cf67;
    text-align: center;
    transition: 0.5s;
    opacity: 0;
    z-index: 10;
    animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
}


.link-dashboard-container .info-popup-active{
    opacity: 1;
    visibility: visible;
}

.toast-popup{
    position: fixed;
    display: flex;
    bottom: 20px;
    text-align: center;
    right: 20px;
    max-width: calc(100vw - 40px);
    visibility: hidden;
    opacity: 0;
    border-radius: 13px;
    min-height: 60px;
    padding: 0 15px;
    transition: 0.5s;
    font-size: 16px;
    font-family: AppFont;
    color: var(--fg-alt-color);
    z-index: 20;
    animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
}

.toast-popup-active{
    opacity: 1;
    visibility: visible;
}

.link-dashboard-container .info-popup span{
    margin: auto;
    font-family: AppFont;
    color: var(--fg-alt-color);
    font-size: 14px;
}

.link-dashboard-container .link1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(33.3% - 20px);
    min-width: 350px;
    height: 100%;
    background-color: var(--bg-color);
    border-radius: 15px;
}

.link-dashboard-container .link1 .img-container{
    display: flex;
    width: 80px;
    height: 100%;
}

.link-dashboard-container .link1 .topup-img{
    background-image: url('../../Images/Icons/Topup\ selected\ +\ background.png');
    width: 64px;
    height: 64px;
    margin: auto;
}

.link-dashboard-container .link1 .info-container{
    position: relative;
    width: 190px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
}

.link-dashboard-container .link1 .info-container .money{
    font-family: AppFontBold;
    padding-left: 18px;
    width: 100%;
    font-size: 28px;
    line-height: 22px;
    text-align:center;
    /* identical to box height, or 79% */
    letter-spacing: -0.672px;
    color: var(--fg-color);
}

.link-dashboard-container .link1 .info-container .iban{
    font-family: AppFont;
    font-size: 14px;
    line-height: 19px;
    max-width: 100%;
    min-width: 160px;
    text-align: center;
    /* identical to box height, or 106% */
    letter-spacing: -0.432px;
    color: #9F9F9F;
}

.link-dashboard-container .link1 .info-container .slider-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.link-dashboard-container .link1 .info-container .slider-container .item-to-show{
    width: 160px;
    overflow: hidden;
}

.link-dashboard-container .link1 .info-container .slider-container .item-to-show .item-visible{
    display: flex;
    transition: 0.5s;
}
.link-dashboard-container .link1 .info-container .slider-container .item-to-show .item-visible div{
    width: 160px;
    height: 100%;
}

.link-dashboard-container .link1 .info-container .slider-container .item-to-show span{
    width: 100%;

}

.link-dashboard-container .link1 .info-container .slider-container .navigation-arrow{
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
}

.link-dashboard-container .link1 .info-container .slider-container .bubbles-container{
    position: absolute;
    display: flex;
    bottom: 15px;
    left: 38%;
}

.link-dashboard-container .link1 .info-container .slider-container .bubbles-container .bubble{
    width: 13px;
    height: 13px;
    border-radius: 1000px;
    border: 2px solid black;
    transition: 0.5s;
}

.link-dashboard-container .link1 .info-container .slider-container .bubbles-container .bubble-active{
    background-color: var(--bg-alt-color);
}

.link-dashboard-container .link1 .actions-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80px;
    height: 100%;
    padding-top: 24px;
}

.link-dashboard-container .link1 .actions-container .navigation-arrow{
    width: 24px;
    height: 24px;
    transform: rotate(90deg);
}

.link-dashboard-container .order-card-container{
    display: flex;
    width: calc(33.3% - 20px);
    min-width: 350px;
    height: 100%;
}

.link-dashboard-container .order-card-container .button-container{
    display: flex;
    align-items: center;
    margin: auto;
    padding: 0 10px;
    width: 220px;
    height: 50px;
    border: 2px solid #000;
    border-radius: 12px;
    cursor: pointer;
}

.link-dashboard-container .order-card-container .button-container img{
    height: 32px;
    width: 32px;
}
.link-dashboard-container .order-card-container .button-container span{
    font-size: 1.2rem;
    font-family: AppFontBold;
    margin-left: 10px;
}

.link-dashboard-container .link{
    position: relative;
    display: flex;
    white-space: nowrap;
    word-wrap: unset;
    flex-wrap: nowrap;
    width: calc(33.3% - 20px);
    min-width: 350px;
    /* height: 116px; */
    background-color: var(--bg-color);
    border-radius: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
}
.link-dashboard-container .slide-container{
    transition: 0.5s;
    transform: translateX(0px);
}

/* .link-dashboard-container .link3{
    position: relative;
    display: flex;
    white-space: nowrap;
    word-wrap: unset;
    flex-wrap: nowrap;
    width: 350px;
    height: 116px;
    background-color: var(--bg-color);
    border-radius: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    overflow: hidden;
} */

.link-dashboard-container img {
    width: 100%;
    /* height: 100%; */
    cursor: pointer;
}

.transaction-group{
    display: flex;
    flex-direction: column;
}

.transaction-group .header{
    display: flex;
    height: 30px;
    font-family: AppFontBold;
    font-size: 16px;
    align-items: center;
    cursor: default;
}

.transaction-group .body{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    min-height: 50px;
    background-color: var(--bg-alt3-color);
    border-radius: 10px;
}

.transaction2-container{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
}

.transaction2-container .profile-pic{
    border-radius: 50px;
    display: flex;
    height: 36px;
    width: 36px;
    overflow: hidden;
    background-color: var(--bg-color);
}
.transaction2-container .profile-pic img{
    margin: auto;
}

.transaction2-container .profile-pic .transaction-type{
    position: absolute;
    top: 21px;
    left: 20px;
}

.transaction2-container .info-container{
    margin: 0px 10px;
    cursor: default;
    min-width: 150px;
    width: calc(100% - 56px);
}

.transaction2-container .info-container .name{
    font-family: AppFontBold;
    font-size: 14px;
    width: 100%;
}

.transaction2-container .info-container .date{
    font-family: AppFont;
    font-size: 0.9rem;
    color: grey
}

.transaction2-container .sum-container{
    font-family:AppFontBold;
    font-size: 0.9rem;
    cursor: default;
}

.transaction2-container .view-more{
    cursor: pointer;
    display: flex;
}

.transaction2-container .view-more img{
    margin: auto;
}
    
.news-container{
    position: relative;
    height: 300px;
    width: 600px;
    max-width: 100vw;
    min-width: 350px;
}

.loading-screen{
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(256, 256, 256, 0.5);
    z-index: 15;
}
/* 
@media (max-width: 728px) {
    .news-container{
        width: 90%;
    }
} */

.news-container .image-container{
    position: absolute;
    width: 100%;
    min-width: 350px;
    max-width: 700px;
    border-radius: 15px;
    overflow: hidden;
    z-index: 0;
}

.news-container .info-container{
    position: relative;
    display: flex;
    width: 100%;
    min-width: 350px;
    max-width: 700px;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px;
    z-index: 2;
}

.news-container .info-container .btn-top{
    display: flex;
    height: 30px;
    width: 90px;
    background: var(--main-color);;
    border-radius: 10px;
    cursor: default;
}

.news-container .info-container .btn-top span{
    margin: auto;
    font-family: AppFontBoldExtra;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.news-container .info-container .title{
    font-family: AppFont;
    color: var(--fg-alt-color);
    max-width: 100%;
    font-size: 40px;
    margin: 20px 0;
    color: default;
}

.news-container .info-container .btn-bottom{
    display: flex;
    width: 120px;
    height: 45px;
    border: 2px solid var(--main-color);;
    border-radius: 15px;
    background: transparent;
    margin-bottom: 10px;
    cursor: pointer;
}

.news-container .info-container .btn-bottom span{
    margin: auto; 
    color: var(--main-color);
    font-family: AppFont;
    font-size: 1.2rem;
}

.custom-checkbox .checkbox-example{
    display: flex;
    width: 40px;
    padding: 0 5px;
    height: 17px;
    background: var(--bg-alt-color);
    border-radius: 15px;
    cursor: pointer;
    transition: all .5s ease;
}

.custom-checkbox .active{
    background: var(--main-color);
}

.custom-checkbox .checkbox-example .circle{
    margin: auto 0;
    position: relative;
    width: 12px;
    height: 12px;
    background: var(--main-color);
    border-radius: 100px;
    transition: all .5s ease;
}

.custom-checkbox .checkbox-example .active{
    left: 18px;
    background: var(--alt-color);
}

.custom-checkbox .checkbox-example .deactive{
    left: 0px;
    background: var(--fg-alt-color);
}

.delete-container{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 50vh;
    left: 50vw;
    width: 350px;
    min-width: 350px;
    height: 400px;
    padding: 40px 20px;
    transform: translate(-50%, -50%);
    background-color: var(--bg-color);
    border-radius: 15px;
    align-items: center;
    justify-content: space-between;
    z-index: 13;
    background-image: url('../../Images/Heads/black\ head.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.delete-container .close-btn{
    position: absolute;
    right: 10px;
    top: 0px;
    background-color: transparent;
    border: none;
    color: var(--fg-alt-color);
    font-size: 1.5rem;
}

.delete-container .message{
    font-family: AppFontBold;
    text-align: center;
    font-size: 1.3rem;
    color: var(--fg-alt-color);
}

.delete-container .dialog-buttons{
    width: 120px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    justify-content: space-between;
}

.delete-container .dialog-buttons button{
    border-radius: 10px;
    border: none;
    height: 45px;
    width: 100%;
    background-color: transparent;
    font-family: AppFontBold;
    font-size: 1.1rem;
}

.delete-container .dialog-buttons .confirm{
    background-color: var(--main-color);;
}

.delete-container .dialog-buttons .deny{
    background-color: var(--bg-alt-color);
    color: var(--main-color);;
}

.popup-with-input{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    width: 350px;
    max-width: 100%;
    min-height: 400px;
    border-radius: 13px;
    padding: 40px 20px;
    background-image: url(../../Images/Heads/black\ head.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transform: translate(-50%, -50%);
    z-index: 12;
}

.popup-with-input .title{
    width: 100%;
    font-size: 24px;
    line-height: 24px;
    font-family: AppFont;
    color: var(--fg-alt-color);
    text-align: center; 
}

.popup-with-input input{
    background-color: transparent;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: 1px solid var(--main-color);
    width: 100px;
    align-self: center;
    color:var(--main-color);
}

.popup-with-input .confirm{
    cursor: pointer;
    width: 100%;
    height: 40px;
    border-radius: 13px;
    background-color: var(--main-color);
    display: flex;
}

.popup-with-input .confirm span{
    margin: auto;
    color: var(--fg-color);
    font-family: AppFontBold;
    font-size: 20px;
}

.popup-with-input .deny{
    cursor: pointer;
    width: 100%;
    height: 40px;
    border-radius: 13px;
    background-color: var(--bg-alt-color);
    display: flex;
    margin-top: 10px;
}

.popup-with-input .deny span{
    margin: auto;
    color: var(--main-color);
    font-family: AppFontBold;
    font-size: 20px;
}

@media (max-width: 1370px) {
    .link-dashboard-container{
        flex-direction: column;
        align-items: center;
    }

    .link-dashboard-container .order-card-container{
        margin: 30px 0px !important;
        height: 116px;
    }

    .link-dashboard-container .link-hidden{
        margin: 30px 0px !important;
 
    }
    
    .link-dashboard-container .link1{
        height: 116px;
    }
}