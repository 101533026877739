/* ALL - START  */
:root {
  --main-color: #1540b7;
  --alt-color: #ff0000;
  --bg-color: #ffffff;
  --bg-alt-color: #000000;
  --bg-alt2-color: #F5F7F0;
  --bg-alt3-color: #eaf4fe;
  --bg-alt4-color: #F3F3F3;
  --bg-alt5-color: #9ecbf8;
  --header-bg-color: #282c34;
  --app-link: #61dafb;
  --fg-color: #000000;
  --fg-alt-color: #ffffff;
  --fg-alt2-color: #c9cacc;
  --fg-alt3-color: #1A1919;
  --fg-alt4-color: #ffffff;
  --fg-alt5-color: #1A1919;
  --outline: #013c8f;
  --invert: 0;
}

.header-links .links span {
  color: var(--fg-alt2-color);
}
img[src='/Images/Icons/Card2.png'], img[src='/Images/Icons/Home2.png'], img[src='/Images/Icons/Topup2.png'], img[src='/Images/Icons/Transaction2.png'] {
  filter: invert(var(--invert));
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--header-bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--fg-alt-color);
}

.App-link {
  color: var(--app-link);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container-fluid{
  padding-right: 0;
  padding-left: 0;
}
