.modal-container-card-details{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    min-height: 500px;
    max-width: 90%;
    background-color: var(--bg-color);
    border-radius: 15px;
    z-index: 12;
    padding: 20px 50px;
}

.modal-container-card-details .close-btn{
    position: absolute;
    right: 10px;
    top: 0;
    background-color: var(--bg-color);
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 2rem;
}

.modal-container-card-details .title-container{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.modal-container-card-details .title-container .title{
    font-family: AppFontBold;
    font-size: 24px;
    cursor: default;
}

.modal-container-card-details .header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 300px;
}

.modal-container-card-details .header .datails-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 48%;
    min-width: 200px;
    min-height: 200px;
    background-color: var(--bg-alt3-color);
    border-radius: 15px;
    margin: auto 0;
    padding: 20px;
}   
.modal-container-card-details .header .datails-container .delivery-address{
    display: flex;
    flex-direction: column;
}

.modal-container-card-details .header .datails-container .confirm-btn{
    background-color: var(--main-color);
    border-radius: 10px;
    min-height: 30px;
    width: 175px;
    max-width: 100%;
    padding: 0 10px;
    padding-top: 5px;
    color: var(--fg-alt-color);
    font-size: 14px;
    font-family: AppFontBold;
    cursor: pointer;
}

.modal-container-card-details .header .card-container{
    position: relative;
    right:0;
    min-width: 320px;
    width: 320px;
    margin: auto 0;
    border-radius: 15px;
    overflow: hidden;
}

.modal-container-card-details .header .card-logo{
    position: relative;
    right:0;
    max-width: 100%;
    max-height: 220px;
    margin: auto 0;
}
.modal-container-card-details .body{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 50%;
    min-width: 200px
}
.modal-container-card-details .body .continue-btn-cont{
    height: clamp(50px, 8vmin, 72px);
    width: 150px;   
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;
    min-width: 100px;
}

.modal-container-card-details .body .continue-btn-cont .continue-btn{
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    border: none;
    color: var(--fg-color);
    font-family: AppFontBold;
    font-weight: 16px;
    border-radius: 10px;
}
.modal-container-card-details .body .change-btn-cont{ 
    height: clamp(50px, 8vmin, 72px);
    width: 200px;   
    margin-top: 10px;
    margin-right: 10px;
    min-width: 150px;
}

.modal-container-card-details .body .change-btn-cont .change-btn{
    background-color: var(--bg-color);
    border: 2px solid var(--main-color);
    border-radius: 10px;
    height: 100%;
    padding: 0 10px;
    color: var(--main-color);
    font-family: AppFontBold;
    font-weight: 16px;
}


@media (max-width:880px) {
    .modal-container-card-details .header{
        flex-direction: column;
        align-items: center;
    }

    .modal-container-card-details .header .datails-container{
        width: 320px;
        margin: 20px 0
    }
}

@media (max-width: 484px) {
    .modal-container-card-details .body{
        flex-direction: column;
        align-items: center;
    }

    .modal-container-card-details .body .change-btn-cont{ 
        margin-right: 0px;
    }

    
    .modal-container-card-details .body .continue-btn-cont{ 
        margin-left: 0;
    }

    .modal-container-card-details .header .datails-container{
        width: 280px;
    }

    .modal-container-card-details .header .card-container{
        width: 280px;
        min-width: 280px;
    }
}

.verify-popup{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 20px;
    width: 350px;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    border-radius: 15px;
    background-image: url('../Images/Heads/black\ head.png');
    background-position: center top;
}

.verify-popup .title{
    color: var(--fg-alt-color);
    font-family: AppFont;
    font-size: 24px;
    align-self: center;
    text-align: center;
}

.verify-popup input{
    background-color: transparent;
    border: none;
    width: 80px;
    border-bottom: 1px solid var(--main-color);
    align-self: center;
    outline: none;
    color: var(--main-color);
    text-align: center;
}

.verify-popup .confirm{
    display: flex;
    width: 100%;
    height: 40px;
    background-color: var(--main-color);
    border-radius: 13px;
    cursor: pointer;
}

.verify-popup .confirm span{
    margin: auto;
    color: var(--fg-color);
    align-items: center;
    justify-content: center;
    font-family: AppFontBold;
    font-size: 20px;
}

.verify-popup .deny{
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    background-color: var(--bg-alt-color);
    border-radius: 13px;
    cursor: pointer;
}

.verify-popup .deny span{
    margin: auto;
    color: var(--main-color);
    align-items: center;
    justify-content: center;
    font-family: AppFontBold;
    font-size: 20px;
}

.confirmation-popup{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 20px;
    width: 350px;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--bg-color);
    z-index: 13;
    border-radius: 15px;
    background-image: url('/src/Images/Heads/black\ head.png');
    background-position: center top;
}

.confirmation-popup .confirmation-message{
    font-family: AppFontBold;
    font-size: 1.2rem;
    margin: 0 auto;
    color: var(--fg-alt-color);
    text-align:center;
}

.confirmation-popup .confirmation-btns{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 100px;
}

.confirmation-popup .confirmation-btns .yes{
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 45px;
    background: var(--main-color);
    border-radius: 10px;
}

.confirmation-popup .confirmation-btns .no{
    display: flex;
    cursor: pointer;
    height: 45px;
    width: 100%;
    background: #000;
    border-radius: 10px;
}
.confirmation-popup .confirmation-btns span{
    margin: auto;
    font-family: AppFontBold;
    color: var(--fg-color);
}
.confirmation-popup .confirmation-btns .no span{
    color: var(--main-color);
}

.overlay2{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 12;
}