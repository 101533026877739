.topup2-container{
    background-color: var(--bg-color);
    display: flex;
    width: calc(100%);
    flex-direction: column;
    border-radius: 15px;
    min-height: 300px;
    padding: 30px !important;
}

@media (max-width: 1370px) {
    .topup2-container{
        width: calc(100% - 45px);
    }
}

.topup2-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.topup2-container  .left-container{
    display: flex;
    width: 48%;
}


.topup2-container .right-container{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 48%;
}

.topup2-container  .right-container-info{
    display: flex;
    justify-content: space-around;
    padding: 20px;
    width: 100%;
    min-width: 300px;
    background-color: var(--bg-alt3-color);
    border-radius: 15px;
}

.topup2-container  .right-container-info .currency-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    background-color: var(--bg-alt3-color);
}
.topup2-container  .right-container-info .currency-container .currency{
    font-family: AppFontBold;
    width: 105px;
    outline: none;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid black;
}


.topup2-container  .right-container-info .currency-container .balance{
    color: grey;
    font-family: AppFont;
}

.topup2-container  .right-container-info .sum-container-pack{
    display: flex;
    flex-direction: column;
    width: 120px;
    justify-content: space-between;
}

.topup2-container  .right-container-info .sum-container{
    display: flex;
    font-family: AppFontBold;
    border-bottom: 1px solid black;
}


.topup2-container  .right-container-info input{
    width: 100px;
    border: 0px;
    background-color: transparent;
    font-family: AppFontBold;
    outline: 0px;
}

.topup2-container  .right-container-info .fee-container{
    font-family: AppFont;
    color: grey;
    font-size: 16px;
}

.topup2-container .right-container .button-container{
    justify-self: center;
    width: 150px;
    margin-top: 10px;
    align-self: flex-end;
}

@media (max-width: 914px) {
    .topup2-container{
        width: 100%;
    }
}

@media (max-width: 992px) {
    .topup2-container  {
        flex-direction: column;
        align-items: center;
    }

    .topup2-container   .left-container{
        width: 300px;
    }

    .topup2-container   .right-container-info{
        width: 300px;
    }

    .topup2-container .currency-btn{
        align-self: center;
        margin-top: 20px;
    }
}

.topup-iframe-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: rgba(1,1,1,0.5);
  z-index: 14;
}

.topup-iframe-container .topup-iframe{
  width: 100%;
  height: 100%;
}