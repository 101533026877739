.profile-photo{
    width: 116px;
    height: 116px;
    left: 968px;
    top: 348px;
    /* background-image: url('../../public/logo192.png');  */
    cursor: default;
    border-radius: 1000px;
    overflow: hidden;
}

.cropper-container{
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1050px) {
    .cropper-container{
        flex-direction: column;
        align-items: center;
    }

    .preview{
        margin-top: 20px
    }
}

.profile-photo img{
    align-self: center;
}

.add-photo-style{
    position: relative;
    width: 296px;
    height: 48px;
    background-color: var(--bg-color);
    border: 1px solid var(--main-color);
    border-radius: 15px;
    cursor: pointer;
}

.add-photo{
    border-radius: 15px;
    background-color: var(--bg-color);
    border: 1px solid black;
    cursor: pointer;
    z-index: -2;
    display: none;
}

.add-photo-style .circle{
    position: absolute;
    width: 22px;
    height: 22px;
    top: 13px;
    left: 16px;
    border-radius: 1000px;
    border: 1px solid var(--main-color);
}

.add-photo-style .circle .vertical{
    position: absolute;
    width: 1.2px;
    height: 9.42px;
    top: 6px;
    left: 10.5px;

    background: var(--main-color);
}

.add-photo-style .circle .horizontal{
    position: absolute;
    width: 8.23px;
    height: 1.2px;
    top: 10px;
    left: 7px;
    background: var(--main-color);
}

.add-photo-style .text{
    position: relative;
    font-family: AppFontBold;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height, or 136% */
    text-align: center;
    top: 3
    px;
    margin-top: 13px;
    color: var(--main-color);
}

.checkbox-container2{
    margin: 0 auto;
    max-width: 100%;
    height: 22px;
}

.main .expiration-popup{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 20px;
    padding-top: 30px;
    transform: translate(-50%, -50%);
    min-width: 300px;
    width: 40%;
    min-height: 300px;
    background-color: var(--bg-color);
    border-radius: 15px;
    z-index: 10;
}

.main .expiration-popup .close-btn{
    position: absolute;
    right: 10px;
    top: 0px;
    border: none;
    background-color: transparent;
    font-size: 1.5rem
}

.main .expiration-popup .message{
    font-family: AppFont;
    font-size: 1.1rem;
}

.main .expiration-popup .continue-btn{
    height: clamp(40px, 7vmin, 60px);
}

.main .expiration-popup .error-message{
    align-self: center;
}