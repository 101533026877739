.notification-page-container{
    position: relative;
    width: 100%;
    min-height: 100px;
}


.notification-page-container .actual-notifications{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100px;
}

.notification-page-container .actual-notifications .header{
    display: flex;
    padding: 0px 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    max-width: 100%;
    width: 810px;
}

.notification-page-container .actual-notifications .header .notification-title{
    font-size: 1.5rem;
    font-family: AppFontBold;
    
}

.notification-page-container .actual-notifications .header .header-links{
    display: flex;
    justify-content: space-between;
}

.notification-page-container .actual-notifications .header .header-links .links{
    display: flex;
}

.notification-page-container .actual-notifications .header .header-links .links button{ 
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border: none;
    background-color: var(--main-color);
    width: 60px;
    height: 31px;
    border-radius: 10px;
    margin: auto;
}

.notification-page-container .actual-notifications .header .header-links .links button span{
    text-align: center;
    justify-self: center;
    font-size: 0.8rem;
    font-family: AppFont;
    margin: auto;
}

.notification-page-container .actual-notifications .header .settings-icon{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.news-page-container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
}

@media (max-width: 1489px) {
    .news-page-container{
        justify-content: center;
    }
}