.setting-page-container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 810px;
    max-width: 100%;
    min-height: 100px;
    background-color: var(--bg-color);
    padding: 30px;
    border-radius: 15px;
    align-self: flex-start;
}

.setting-page-container .settings-title{
    font-family: AppFont;
    font-size: 1.8rem; 
}

.setting-page-container .group{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.setting-page-container .group .group-title{
    font-family: AppFont;
    font-size: 1.5rem;
}
.setting-page-container .group .item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 10px 0;
}

.setting-page-container .group .item span{
    font-family: AppFont;
    font-size: 1.1rem;
}

.setting-page-container .save-btn-container{
    align-self: center;
    margin-top: 20px;
    height: 40px;
    width: 200px;
}