.back-btn-container2{
    margin-bottom: 10%;
    width: 100%;
    height: clamp(40px, 8vmin, 56px);
}

.bell{
    width: 43px;
    height: 53px;
    margin-bottom: 15px;
    background-image:url('../Images/Icons/Bell.png');
}

.verifing-info-text{ 
    width: 100%;
    height: 22px;
    left: 859px;
    top: 565px;

    font-family: AppFontBold;
    font-size: 24px;
    line-height: 22px;

    /* identical to box height, or 92% */
    text-align: center;
    letter-spacing: -0.576px;

    color: var(--fg-color);
}
