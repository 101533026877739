.wolves-chart{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 100%;
    min-height: 100%;
}

.wolves-chart .hexagon{
    display: flex;
    position: relative;
    height: 250px;
    width: 250px;
}  

.wolves-chart .hexagon .sum-spent{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-family: AppFontBold;
}

.wolves-chart .labels-container{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 250px;
    height: 100%;
    margin: auto;
}

.wolves-chart .labels-container .label{
    display: flex;
    align-items: center;
}

.wolves-chart .labels-container .bubble{
    width: 8px;
    height: 8px;
    border-radius: 10000px;
    background-color: var(--bg-alt-color);
}
.wolves-chart .labels-container .label{
    width: 100%;
}
.wolves-chart .labels-container .label .text-wolves{
    font-size: 16px;
    font-family: AppFont;
    text-align: center;
    margin-left: 7px;
    cursor: default;
    width: 100%;
    text-align: center;
}

@media (max-width: 565px) {
    .wolves-chart {
        justify-content: center;
    }
    .wolves-chart .labels-container{
        align-items: center;
    }
}

