.submit-container{
    width: 100%;
    height: clamp(40px, 8vmin, 56px);
    margin-bottom: 10%;
}

.slideshow-container{
    height: 100%;
    width: 100%;
    z-index: -10;
}

.back-btn-container{
    width: 100px;
    margin-bottom: 20px;
    display: flex;
    cursor: pointer;
    background-color: transparent;
}

.back-btn-container span{
    height: 19px;
    font-family: AppFontBold;
    font-size: 22px;
    line-height: 19px;
    margin-left: 10px;
    /* identical to box height, or 106% */  
    color: var(--fg-alt3-color);
}

.back-btn-container .back-arrow{
    width: 23px;
    height: 16px;
    left: 2px;
    top:3px;
    background-image: url(../Images/Icons/Back\ arrow.png);
}

.verification-text{
    position: relative;
    font-family: AppFontBold;
    font-size: clamp(1.8rem, 4vw, 3.2rem);
    line-height: 22px;
    /* identical to box height, or 46% */
    letter-spacing: -1.152px;
    color: var(--fg-color);
    cursor: default;
}

.checkmark-container{
    width: 111px;
    height: 110px;
    justify-self: center;
    align-self: center
}

.checkmark-container span{
    height: 22px;
    bottom: 0px;
    font-family: AppFont;
    font-size: 24px;
    line-height: 22px;
    /* identical to box height, or 92% */
    letter-spacing: -0.576px;
    color: var(--fg-color);
}