.calendar-container{
    position: relative;
    width: 100%;
}

.calendar-container .text{
    font-family: AppFont;
    font-size: 24px;  
    line-height: 19px;
    /* identical to box height, or 106% */
    color: var(--fg-alt3-color);
    cursor: default;
}

.calendar-container .calendar-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 156px;
    width: 100%;
    height: clamp(40px, 7vmin, 60px);
    /* border: 1px solid var(--main-color); */
    border-radius: 13px;
    cursor: pointer;
    padding: 18px;
    background-color: var(--bg-alt3-color);
}

.calendar-container .calendar-header-ranged{
    height: 50px !important;
    border: none !important;
    padding: 0;
    justify-content: space-around;
}

.calendar-container .calendar-header .arrow2{
    min-width: 16px;
    height: 16px;
    margin-right: clamp(0px, 1vw, 20px);
    background-image: url('../../Images/Icons/triangle.png'); 
    background-size: 16px;
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.calendar-container .calendar-header .arrow2-open{
    animation-name: arrow-open;
    animation-duration: 0.5s;
}

.calendar-container .calendar-header .arrow2-close{
    animation-name: arrow-close;
    animation-duration: 0.5s;
}

@keyframes arrow-open {
    from {transform: rotate(0deg);}
    to {transform: rotate(180deg);}
}

@keyframes arrow-close {
    from {transform: rotate(180deg);}
    to{transfrom: rotate(-0deg)}
}

.calendar-container .calendar-header .header-text{
    font-family: AppFont;
    font-size: 20px;
    line-height: 19px;
    color: var(--fg-alt2-color);
    /* identical to box height, or 106% */
}

.calendar-container .calendar-header .header-with-text{
    color: var(--fg-color);
}

@media (max-width: 760px) {
    .calendar-container .text{
        font-size: 20px;
    }
    .calendar-container .calendar-header .header-text{
        font-size: 16px;
    }
}

.calendar-container .body{
    position: absolute;
    top: -200px;
    width: 100%;
    min-width: 320px;
    min-height: 455px;
    border-radius: 5px;
    overflow: hidden;
    border:1px solid #000;
    z-index: 1;
}

.calendar-container .bodyRanged{
    min-height: 405px;
    top: -50%;
    /* left: -60px; */
    width: 100%;
    min-width: 320px; 
}

.check-click-container{
    width: 100%;
    height: 520px;
    top: -120px;
    z-index: 0;
    visibility: hidden;
}

.calendar-container .body .header{
    height: 100px;
    background-color: var(--main-color);
}

.calendar-container .body .headerRanged{
    height: 50px;
    padding-left: 20px; 
    background-color: var(--main-color);
}

.calendar-container .body .header .select-date-text{
    position: relative;
    top: 5%;
    left: 10px;
    text-transform: uppercase;
}
.calendar-container .body .header .selected-date-container{
    padding-top: 20px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calendar-container .body .header .selected-date-text{
    width: 60%;
    font-size: 30px;
    padding-left: 10px;
    font-weight: bold;
}

@media (max-width: 996px){
    .calendar-container .body .header .selected-date-text{
        font-size: 24px;
    }
}

@media (max-width: 576px){
    .calendar-container .body .header .selected-date-text{
        font-size: 18px;
        font-weight: bold;
    }
}

.calendar-container .body .header .ranged-header-text{
    width: 90%;
}

.calendar-container .body .header .selected-date-container .edit-icon{
    background-color: var(--main-color);
    color:var(--main-color);
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-size: 24px;
    background-image: url('../../Images/Icons/edit.jpg');
}

.calendar-container .body .header .selected-date-container .manual-container{
    width: 115px;
    border: 0px;
    height: 100%;
    border-bottom: 1px solid black;
    background-color: transparent;
    font-size: 18px;
    line-height: 19px;
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
}

.manual-container-open{
    animation-name: open-manual-input;
    animation-duration: 0.5s;
}

.manual-container-close{
    animation-name: close-manual-input;
    animation-duration: 0.5s;
}

@keyframes open-manual-input{
    0% {transform: translateX(-110px);}
    
    100% {transform: translateX(0px);}
}


.calendar-container .body .header .selected-date-container .manual-container:focus{
    outline: 0px;
}

.calendar .controlls-container{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.calendar .controlls-container .change-month-container{
    align-self: center;
    justify-content: flex-end;
    min-width:20%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    margin-right: 10%;
}

.calendar .controlls-container .change-month-container .next{
    background-image: url(../../Images/Icons/next.png);
    height: 15.7px;
    width: 16.1px;
    cursor: pointer;
}

.calendar .controlls-container .change-month-container .prev{
    background-image: url(../../Images/Icons/next.png);
    height: 15.7px;
    width: 16.1px;
    transform: rotate(180deg);
    cursor: pointer;
}

.selectors-container{
    padding: 3% 6%;
    cursor: pointer;
    width: 250px;
    display: flex;
    flex-direction: row;
    font-family: AppFont;
}

.calendar .dd-month-container{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 5px 5px;
    background-color: var(--bg-color);;
    border-top: 1px solid #000;
}

.calendar .dd-month-container .scrollable{
    display: grid;
    width: 100%;
    padding: 10%;
    grid-template-columns: repeat(3, 1fr);   
    gap: 10px;
}

.calendar .dd-month-container .scrollable div{
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    margin: 5px 0;
    cursor: pointer;
    font-family: FSAlberPro;
}

.calendar .dd-years-container{
    position: absolute;
    width: 100%;
    height: 350px;
    padding-top: 10px;
    border-radius: 0px 0px 5px 5px;
    background-color: var(--bg-color);;
    border-top: 1px solid #000;
}


.calendar .dd-years-container .scrollable{
    display: grid;
    grid-template-columns: repeat(5, 1fr);   
    
}

.calendar .dd-years-container .scrollable div{
    padding: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    font-family: AppFont;
}


.calendar  .scrollable div:hover{
    background-color: var(--main-color);
}
.arrow-indicator{
    width: 0px;
    height: 0px;
    margin: 5px 10px;
    border-left: 8px solid transparent;
    border-top: 9px solid #000;
    border-right: 8px solid transparent;
}

.calendar {
    position: relative;
    width: 100%;
    height: 355px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--bg-color);
}

.days-container{
    width: 100%;
    min-height: 250px;
    margin-top: 10px;
}

.days-name{
    width: 89%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: AppFont;
}

.days-container .days{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: auto;
    height: clamp(60px, 10vw, 100px);
    margin-top: 10px;
    width: 100%;
}

.days-container .days .day-selected{
    background-color: var(--main-color);
}

.days div{
    justify-content: center;
    align-content: center;
    margin: auto;
    height: clamp(30px, 8vmax, 40px); 
    width: clamp(30px, 8vmax, 40px); ;
    border-radius: 1000px;
    flex: 13%;
    font-family: FSAlberPro;
}

.days div span{
    margin: auto;
}

.days div:hover{
    background-color: var(--main-color) !important;
}

.btns-container{
    display: flex;
    margin-right: 10%;
    align-self: flex-end;
    justify-self: flex-end;
}

.btns-container button{
    margin-right: 20%;
    background-color: transparent;
    border: 0px;
    color: var(--main-color);
    font-family: AppFontBold;
    text-transform: uppercase;
    cursor: pointer;
    height: 30px;
    border-radius: 20px;
}

.btns-container button:hover{
    background-color: var(--bg-alt3-color);
}

.react-datepicker-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 5px;
}

.react-datepicker_input-container{
    position: relative;
    width: 100%;
    height: 48px;
    background-color: var(--fg-color);
}

.react-datepicker_input-container input{
    position: relative;
    width: 100%;
    height: 48px !important;
}
