.right .pin-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.right .pin-container .message{
    font-family: AppFont;
    font-size: 1.2rem;
    text-align: center;
}