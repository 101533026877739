.analytics-container{
    background-color: transparent;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    min-height: 300px;
    width: 100%;
}

.analytics-container .download{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--bg-color);
    border-radius: 15px;
    margin-bottom: 30px;
    padding: min(30px, 2.5%);
}

.analytics-container .download .analytics-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.analytics-container .download .analytics-header .analytics-title{
    font-size: 1.4rem;
    font-family: AppFontBold;
}

.analytics-container .download .analytics-header .analytics-icons{
    display: flex;
}

.analytics-container .download .analytics-header .analytics-icons img{
    cursor: pointer;
}

.analytics-container .download .date-selectors{
    display: flex;
    justify-content: space-between;
    height: 40px;
    max-width: 400px;
}

.analytics-container .download .date-selectors .year-selector{
    width: 80px;
}

.analytics-container .download .date-selectors .special-selector{
    width: 110px;
}

.analytics-container .download .date-selectors .month-selector{
    width: 70px;
}

.analytics-container .message-container{
    position: fixed;
    display: flex;
    justify-content:flex-end;
    bottom: 20px;
    right: 20px;
    margin-top: 30px;
    width: 350px;
    height: 70px;
    background-color: transparent;
}

.analytics-container .message-container .message{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    width: max(300px, 40%);
    height: 100%;
    border-radius: 10px;
}

.analytics-container .message-container .message span{
    font-family: AppFont;
    width: 210px !important;
    font-size: 0.8rem;
    color: var(--fg-alt-color);
}

@media (max-width: 1370px) {
    .analytics-container{
        width: calc(100% - 45px);
    }
}

@media (max-width: 914px) {
    .analytics-container{
        width: 100%;
    }
}