.card-add-page{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 370px;
    background-color: var(--bg-color);
    border-radius: 15px;
    padding: 30px;
}

.card-add-page > div{
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-add-page > div .title{
    font-size: 24px;
    font-family: AppFont;
    width: 320px;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}

.card-add-page > div .card-img{
    width: 320px;
    border-radius: 15px;
}

.card-add-page > div .add-button{
    position: absolute;
    bottom: 10px;
    left: 35px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    width: 75px;
    height: 30px;
    border-radius: 13px;
    background-color: var(--main-color);
    cursor: pointer;
}

.card-add-page > div .add-button span{
    font-size: 16px;
    font-family: AppFontBold;
    margin-left: 10px;
}

@media (max-width: 1370px) {
    .card-add-page{
        width: calc(100% - 45px);
    }
}  

@media (max-width: 965px) {
    .card-add-page{
        flex-direction: column;
    }

    .card-add-page .real-card{
        margin-top: 20px
    }
}

@media (max-width: 914px) {
    .card-add-page{
        width: 100%;
    }
}
