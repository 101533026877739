.modal-container-new-address{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    min-height: 600px;
    max-width: 90%;
    background-color: var(--bg-color);
    border-radius: 15px;
    z-index: 12;
    padding: 20px 40px;
}

.modal-container-new-address .close-btn{
    position: absolute;
    right: 10px;
    top: 0;
    background-color: var(--bg-color);
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 2rem;
}

.modal-container-new-address .header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 300px;
}

.modal-container-new-address .header .title-container{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.modal-container-new-address .header .title-container .title1{
    font-family: AppFontBold;
    font-size: 42px;
    cursor: default;
}

.modal-container-new-address .header .title-container .subtitle1{
    font-family: FSalberPro;
    font-size: 16px;
    color: #ACB6C5;
    cursor: default;
}

.modal-container-new-address .header .card-container{
    position: relative;
    right:0;
    max-height: 220px;
    max-width: 70%;
    min-width: 300px;
    margin: auto 0;
    border-radius: 15px;
    overflow: hidden;
}

.modal-container-new-address .header .card-logo{
    position: relative;
    right:0;
    max-width: 100%;
    min-width: 300px;
    max-height: 220px;
    margin: auto 0;
}

.modal-container-new-address .body{
    display: flex;
    flex-direction: column;
}

.modal-container-new-address .body .input-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal-container-new-address .body .input-container > div{
    width: 48%;
    height: clamp(75px, 14vmin, 96px);
    min-width: 310px;
}

.modal-container-new-address .body .single-input{
    width: 48%;
    height: clamp(75px, 14vmin, 96px);
    min-width: 310px;
    align-self: flex-start;
}

@media (max-width: 800px) {
    .modal-container-new-address .header{
        flex-direction: column;
        align-items: center;
    }

    .modal-container-new-address .header .title-container{
        align-items: center;
    }

    .modal-container-new-address .header .card-container{
        margin: 10px 0;
    }

    .modal-container-new-address .body .input-container{
        flex-direction: column;
        align-items: center;
    }

    .modal-container-new-address .body .input-container > div{
        width: 100%;
        min-width: 300px;
    }

    .modal-container-new-address .body .single-input{
        width: 100%;
        min-width: 300px;
        align-self: center;
    }
}
