.offers-container{
    display: flex;
    row-gap: 32px;
    column-gap: 31px;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100%);
    min-height: 300px;
    align-self: flex-start;
    border-radius: 15px;
}

.sigle-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 37px 0px;
    height: 414px;
    width: 389px;
    max-width: 100vw;
    background: linear-gradient(0deg, var(--bg-color), var(--bg-color));
    box-shadow: 0px 2px 7px rgba(172, 182, 197, 0.3);
    border-radius: 15px;
    cursor: pointer;
}

.sigle-item img{
    margin: 0 auto;
}

.sigle-item .info{
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-left: 32px;
}

.sigle-item .info .name{
    font-family: AppFont;
    font-size: 18px;
    line-height: 31px;
    /* identical to box height, or 172% */
    letter-spacing: -0.432px;
    color: var(--fg-color);
}

.sigle-item .info .price{
    font-family: AppFontBold;
    font-size: 20px;
    line-height: 31px;
    /* identical to box height, or 155% */
    letter-spacing: -0.48px;
    color: var(--fg-color);
}