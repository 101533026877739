.onfido-container{
    height: 100%;
    display: flex;
}

.kyc-container{
    min-width: 300px;
    max-height: 100%;
    margin: 50px auto;
}

@media (max-width: 992px) {
    .kyc-container{
        position: absolute;
        top: 160px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.kyc-container .kyc-title{
    font-family: AppFont;
    font-size: 1.2rem;
    text-align: center;
}