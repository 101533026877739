.page-container{
    width: 100%;
    height: 100%;
    display: flex;
}

.header-container{
    position: relative;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    min-height: 130px;
    padding-right: 10vw;
    background: #FBFBFB;
    z-index: 3;
}

@media (max-width: 1200px) {
    .header-container{
        padding-right: 0;
    }
}

.header-container .extra-padding{
    position: relative;
    width: 80px;
    height: 100%;
    background-color: transparent;
    pointer-events: none;
}

.header-container .dashboar-text{
    display: flex;
    position: relative;
    align-self: center;
    background-color:transparent;
    font-family: AppFontBold;
    font-size: 18px;
    line-height: 19px;
    left: 10px;
    /* identical to box height, or 106% */
    color: var(--fg-alt3-color);
}

.header-container .dashboar-text .menu-icon{
    width: 23px;
    height: 23px;
    cursor: pointer;;
    background-image: url('../Images/Icons/list.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.header-container .dashboar-text .arrow-header{
    width: 23px;
    height: 16px;
    align-self: center;

    margin: 0px 10px;
    background-image: url('../Images/Icons/Back\ arrow.png');
    background-size: cover;
    background-repeat: no-repeat;
    transform: rotate(180deg);
}

.header-container .right-half{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: space-between;
    position: relative;
    height: 100%;
    left:30px;
    width: calc(100% - 150px - 130px);
    background-color: transparent;
}

@media (min-width: 914px) {
    .header-container .dashboar-text .menu-icon{
        visibility: hidden;
        position: absolute;
        z-index: -10;
        pointer-events: none;
    }
    
    .logo-container .arrow-navigation{
        visibility: hidden;
        position: absolute;
        pointer-events: none;
    }
}

.header-container .dashboar-text .text-header{
    cursor: default;
}

@media (max-width: 932px) {
    .header-container .dashboar-text .text-header{
        visibility: hidden;
        position: absolute;
    }

    .header-container .dashboar-text .arrow-header{
        visibility: hidden;
        position: absolute;
    }

    .header-container .right-half{
        width: calc(100% - 114px);
    }
}


.header-container .right-half .searchbar-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: 56px;
    width: 40%;
    min-width: 200px;
    max-width: 300px;
    background: var(--bg-alt4-color);
    border-radius: 14px;
}

.header-container .right-half .overlay-2{
    position: fixed;
    left: 85px;
    top: 130px;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.349213);
}

@media (max-width: 914px) {
    .header-container .right-half .overlay-2{
        left: 0px
    }
}

.header-container .right-half .search-bar{
    position: relative;
    display: flex;
    justify-content: space-between;
    left:0px;
    padding-left: 15px;
    height: 48px;
    width: 90%;
    border-radius: 14px;
    border: 0px;
    font-family: AppFont;
    font-size: 17px;
    line-height: 29px;
    text-align: start;
    /* identical to box height, or 106% */
    background: var(--bg-alt4-color);
    color: var(--fg-color);
}

.header-container .right-half .search-bar::placeholder{
    color: #9F9F9F;
}

.header-container .right-half .search-bar:focus{
    outline: none;
}

.searchbar-container-outlined{
    outline: 1px solid var(--outline);
    background-color: var(--bg-alt-color);
}

.header-container .right-half .searchbar-container .icon-container{
    width: 15%;
}

.header-container .right-half .icon-container .search-icon{
    position: absolute;
    right: 5%;
    top:35%;
}

.header-container .right-half .notification-bell-container{
    position: relative;
    width: 56px;
    height: 56px;
    margin: 0 15px;
    border-radius: 10px;
    background-color: var(--bg-alt3-color);
    cursor: pointer;
}

.header-container .right-half .notification-bell-container .notification-bell-icon{
    align-self: center;
    width: 26;
    height: 26;
    margin: 18px;
}

.header-container .right-half .notification-bell-container .bubble{
    position: relative;
    display: flex;  
    align-items: center;
    justify-content: center;
    background-color: var(--main-color);
    left: 80%;
    bottom: 115%;
    width: 21px;
    height: 21px;
    border-radius: 1000px;
}

.header-container .right-half .notification-bell-container .bubble span{
    justify-self: center;
    align-self: center;
    font-size: 12px;
    font-family: AppFont
}

.header-container .right-half .hello-user-container{
    display: flex;
    position: relative;
    align-items: center;
    height: 59px;
}

.header-container .right-half .hello-user-container span{
    font-family: AppFont;
    font-size: 17px;
    line-height: 29px;
    /* identical to box height, or 171% */
    letter-spacing: -0.408px;
    color: var(--fg-color);
    cursor: default;
}

.header-container .right-half .hello-user-container .profile-pic{
    margin-left: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 1000px;
}

.hello-user-container::-webkit-scrollbar {
    display: none;
    color: transparent;
    background-color: transparent;
    width: 0;
    opacity: 0;
}

.hello-user-container::-webkit-scrollbar-track {
    display: none;
    color: transparent;
    background-color: transparent;
    width: 0;
    opacity: 0;
}

.hello-user-container::-webkit-scrollbar-thumb {
    display: none;
    color: transparent;
    background-color: transparent;
    width: 0;
    opacity: 0;
}

.hello-user-container .hide{
    position: absolute;
    background-color: transparent;
    top: 95px;
    left: 50%;
    transform: translateX(-75%);
    height: 770px;
    max-height: calc(100vh - 150px);
    width: 310px;
    overflow: hidden;
    z-index: 9;
}

.hello-user-container::-webkit-scrollbar{
    display: none;
    background-color: transparent;
    opacity: 0;
}

.hello-user-container::-webkit-scrollbar-thumb{
    display: none;
    background-color: transparent;
    opacity: 0;
}

.navigation-container{
    display: flex;
    flex-direction: column;
    width: 85px;
    height: calc(100% + 20px);
    background-color: var(--bg-color);;   
}

.navigation-container .logo-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 130px;
    width: 85px;
}

/* .navigation-container .logo{
    margin: auto;
} */

.navigation-container .items-container{
    display: flex;
    flex-direction: column;
    width: 85px;
    height: calc(100% - 115px);
    background-color: var(--bg-color);
}

.navigation-container .items-container .navigation{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
    margin-top: 70%;
}

.items-container .btn-logout{
    background-color: var(--main-color);
    border: 0px;
    border-radius: 10px;
    height: 39px;
    width: 59px;
    align-self: center;
    cursor: pointer;
    margin-bottom: 20px;    
    font-family: AppFontBold;
    font-size: 12px;
    line-height: 19px;
    /* identical to box height, or 158% */
    color: var(--fg-alt4-color);
}

.right-menu-continar{
    position: absolute;
    width: calc(100% + 20px);
    height: 770px;
    background-color: var(--bg-color);
    box-shadow: 0px 8px 16px rgba(172, 182, 197, 0.3);
    z-index: 10;

    animation: growDown 0.5s forwards; 
}

@keyframes growDown {
    0%{ transform: translateY(-100%);}
    100%{ transform: translateY(0%);}
}

.view-tc-rightMenu{
    margin-right: 0 !important;
    scrollbar-width: none;
    width: calc(100% + 20px);
    overflow-x: hidden !important;
}

.track-vertical-tc-rightMenu{
    top: 5px;
    bottom: 5px;
    right: 5px;
    display: block !important;
    z-index: 11;
    background: var(--bg-alt3-color);
    border-radius: 4.5px;
}

.thumb-vertical-tc-rightMenu{
    display: block !important;
    cursor: pointer;
    background: var(--main-color);
    border-radius: 4.5px;
}

.right-menu-continar .items-container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    height: 100%;
    background-color: var(--bg-color);
}

.main-container{
    position: absolute;
    width: calc(100% - 165px);
    height: auto;
    min-height: 100%;
    top:114px;
    left:85px;
    padding: 50px 40px;
    background-color: var(--bg-alt2-color);
}

.home-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.home-container .links-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.home-container .links-container .topup-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 128px;
    width: 33%;
    max-width: 389px;
    min-width: 250px;
    background-color: var(--bg-color);
    box-shadow: 0px 2px 7px rgba(172, 182, 197, 0.3);
    border-radius: 15px;
    cursor: pointer;
    word-wrap: break-word;
}

.home-container .links-container .topup-container .content{
    display: flex;
    flex-direction: row;
    width: 84%;
    height: 64px;
}

.home-container .links-container .topup-container .content .text-container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 24px;
}


.home-container .links-container .topup-container .content .text-container .text1{
    font-family: AppFont;
    font-size: 28px;
    line-height: 22px;
    /* identical to box height, or 79% */
    letter-spacing: -0.672px;
    color: var(--fg-color);
}

.home-container .links-container .topup-container .content .text-container .text2{
    position: absolute;
    font-family: AppFont;
    font-size: 18px;
    line-height: 19px;
    top: 43px;
    width: 110%;
    /* identical to box height, or 106% */
    letter-spacing: -0.432px;
    color: #9F9F9F;
}

.home-container .links-container .link1{
    position: relative;
    display: flex;
    height: 128px;
    width: 30%;
    max-width: 401px;
    min-width: 200px;
    border-radius: 17px;
    overflow: hidden;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.349213), rgba(0, 0, 0, 0.349213)), url(../Images/Wolver\ logos/Mask\ Copy\ 2.png), var(--fg-alt3-color);
    border-radius: 17px;
    cursor: pointer;
}
.home-container .links-container .link1 .info{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 261px;
    height: 61px;
    margin-left: 22px;
    align-self: center;
}

.home-container .links-container .link1 .info span{
    font-family: AppFont;
    font-size: 24px;
    line-height: 22px;

    /* identical to box height, or 92% */
    letter-spacing: -0.576px;

    color: var(--fg-alt-color);
    word-wrap: wrap;
}

.home-container .links-container .link1 .info button{
    position: absolute;
    width: 119px;
    height: 27px;
    bottom: 0px;
    border-radius: 0px;
    border: 0px;
    background-color:var(--main-color);

    font-family: AppFontBoldExtra;
    font-size: 10px;
    line-height: 19px;

    /* identical to box height, or 190% */
    text-transform: uppercase;

    color: var(--fg-alt3-color);
}

.home-container .links-container .link2{
    position: relative;
    display: flex;
    height: 128px;
    width: 30%;
    max-width: 324px;
    min-width: 150px;
    border-radius: 17px;
    overflow: hidden;

    background: url(../Images/Wolver\ logos/Mask.png), var(--fg-alt3-color);
    border-radius: 17px;
    cursor: pointer;
}

.home-container .grid-container{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
    height: auto;
    margin-top: 32px;
}

@media (max-width: 1165px) {   
    .home-container .grid-container{
        justify-content: center;
    }

    .home-container .grid-container .item{
        width: 700px !important ;
    }
}

@media (max-width: 1370px) {
    .home-container .grid-container{
        flex-direction: column;
        align-items: center;
    }

    .home-container .grid-container .item{
        max-width: 100%;
        width: 700px !important;
    }
}

.home-container .grid-container > div{
    background: var(--bg-color);
    box-shadow: 0px 2px 7px rgba(172, 182, 197, 0.3);
    border-radius: 15px;
}
.home-container .grid-container .item{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: calc(50% - 15px);
    padding: 20px;
    min-width: 340px;
    min-height: 400px;
}

.home-container .grid-container .item .inner-container{
    width: 100%;
    height: 100%;
}

.home-container .grid-container .item .inner-container .top{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.home-container .grid-container .item .inner-container .text{
    position: relative;
    font-family: AppFont;
    font-size: 24px;
    width: 205px;
    /* identical to box height, or 92% */
    letter-spacing: -0.576px;
    color: var(--fg-color);
    margin:11px 0px;
    cursor: default;
}

.home-container .grid-container .item .inner-container .wolveschart-container{
    position: relative;
    display: flex;
    align-items: center;
    min-height: 350px;
}

.home-container .grid-container .item .inner-container .top .date-picker-header{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 270px;
    right: 0px;
    width: 50%;   
    height: 60px;
    background: var(--bg-alt3-color);
    border-radius: 10px;
    cursor: pointer;
}

.home-container .grid-container .item .inner-container .top .date-picker-header .image-container{
    width: 24px;
    height: 28px;
    margin-left: 10px;
}

.home-container .grid-container .item .inner-container .top .date-picker-header .date-picker-container{
    position: relative;
    width: 100%;
    height: 40px;
}
.home-container .grid-container .item .inner-container .top .date-picker-header .hidden{
    display: hidden;
    visibility: hidden;
}

.home-container .grid-container .item .inner-container .middle{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    top: 30px;
    width: 100%;
    height: 40px;
}

.home-container .grid-container .item .inner-container .middle .transaction-type-container{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 43%;
    height: 100%;
}

.home-container .grid-container .item .inner-container .middle .transaction-type-container div{
    position: relative;
    display: flex;
    width: 93px;
    height: 100%;
    align-items:center;
    justify-content: center;

    font-family: AppFontBold;
    font-size: 16px;
    line-height: 12px;
    text-align: center;

    color: #187CF6;
    cursor: pointer;
}

.home-container .grid-container .item .inner-container .middle .transaction-type-container .selected{
    border: 1px solid #187CF6;
    box-sizing: border-box;
    border-radius: 10.5px;
}

.home-container .grid-container .item .inner-container .middle .time-interval-container{
    position: absolute;
    left: 54.5%;
    top: -3px;
    width: 24%;
    min-width: 100px;
    height: 100%;
}

.home-container .grid-container .item .inner-container .middle .time-interval-container .css-yfjueu-control{
    padding-left: 0px !important;
}

.home-container .grid-container .item .inner-container .middle .time-interval-container .css-woivcq-control{
    padding-left: 0px !important;
}

.home-container .grid-container .item .inner-container .chart-container{
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 33px;
    scrollbar-width: thin;
    height: 300px;
    scrollbar-color: var(--main-color) transparent;
}

.home-container .grid-container .item .inner-container .chart-container::-webkit-scrollbar{
    background-color: transparent;
}

.home-container .grid-container .item .inner-container .chart-container::-webkit-scrollbar-thumb{
    background-color: var(--main-color);
}

.home-container .grid-container .item .inner-container .notification-container{
    position: relative;
    max-width: 100%;
    height: 300px;
    margin-top:29px;
    overflow: hidden !important;
}


.home-container .grid-container .item .inner-container .notification-container .view-tc{
    width: 100%;
}

.home-container .grid-container .item .inner-container .filter-container2{
    position: relative;
    width: 45%;
    height: 60px;
    min-width: 250px;
}

.home-container .grid-container .item .inner-container .middle .information-contaier{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 46px;
}

.home-container .grid-container .item .inner-container .middle .information-contaier .time-interval{
    font-family: AppFont;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height, or 136% */
    letter-spacing: -0.336px;
    color: #ACB6C5;
}

.home-container .grid-container .item .inner-container .middle .information-contaier .money{
    font-family: AppFontBold;
    font-size: 18px;
    margin: auto 0;
    line-height: 19px;
    /* identical to box height, or 106% */
    letter-spacing: -0.432px;
    color: var(--fg-color);
}

.home-container .grid-container .item .inner-container .middle .buttons-container{
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: calc(100% - 88px);
    justify-content: flex-end;
}

.home-container .grid-container .item .inner-container .middle .buttons-container .search-area{
    margin-right: 10px;
    overflow: hidden;
    width: calc(100% - 100px);
}

.home-container .grid-container .item .inner-container .middle .buttons-container .search-area .search-input{
    border: none;
    height: 99%;
    width: 100%;
    outline: none;
    animation-fill-mode: forwards; 
    -moz-animation-fill-mode: forwards; 
    border-bottom: 1px solid var(--main-color);
}

.home-container .grid-container .item .inner-container .middle .buttons-container .search-area .activated{
    animation-name: activated;
    animation-duration: 0.5s
}

.home-container .grid-container .item .inner-container .middle .buttons-container .search-area .deactivated{
    animation-name: deactivated;
    animation-duration: 0.5s
}

@keyframes activated{
    from {transform:translateX(100%);}
    to {transform: translateX(0%);}
}

@keyframes deactivated{

    to {transform: translateX(100%);}
}

.home-container .grid-container .item .inner-container .middle .buttons-container .search-area .search-input::placeholder{
    font-family: AppFont;
    font-size: 1.1rem;
}

.home-container .grid-container .item .inner-container .middle .buttons-container .search{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: var(--bg-alt3-color);
    border-radius: 10px;
    cursor: pointer;
    margin-right: 16px
}

.home-container .grid-container .item .inner-container .middle .buttons-container button{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 88px;
    background-color: var(--main-color);
    border: 0px; 
    border-radius: 10px;
    cursor: pointer;
}

.home-container .grid-container .item .inner-container .middle .buttons-container button span{
    color: var(--fg-alt-color);
    font-family: AppFont;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
}

.home-container .grid-container .item .inner-container .transactions-container-dashboard{
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 70px;
    height: 290px;
    bottom: 0px;
}

@media (max-width: 750px) {
    .home-container .grid-container .item{
        width: 100%;
    }
    .home-container .grid-container .item .inner-container .transaction-history{
        margin-top: 20px;
    }
}

@media (max-width: 785px) {
    .header-container .right-half{
        justify-content: center;
    }
}

@media (max-width: 532px) {
    .header-container .hide{
        top: 60px;
        transform: translateX(-60%);
    }
}

@media (max-width: 535px){
    .home-container .grid-container .item .inner-container .top{
        justify-content: center !important;
    }

    .home-container .grid-container .item .inner-container .text{
        text-align: center;
    }
}