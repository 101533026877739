.terms-and-cond-page{
    width: calc(100%);
}

@media (max-width: 1370px) {
    .terms-and-cond-page{
        width: calc(100% - 45px);
    }
}

@media (max-width: 914px) {   
    .terms-and-cond-page{
        width: 100%;
    }
}

.terms-and-cond-page .container{
    position: relative;
    width: 100%;
    height: 100%;
    max-width: unset;
    min-height: 300px;
    padding: 30px;
    margin-top: 20px;
    border-radius: 15px;
    background: var(--bg-color);
    box-shadow: 0px 2px 7px rgba(172, 182, 197, 0.3);
    border-radius: 15px;
}

.terms-and-cond-page .container .scrollbar{
    height: 150px;
}

.view-tc{
    padding-right: 30px;
}

.track-vertical-tc{
    width: 9px;
    top: 2px;
    bottom: 2px;
    right: 0px;
    background: var(--bg-alt3-color);
    border-radius: 4.5px;
}

.track-hidden{
    background: transparent;
    display: none;
}

.thumb-vertical-tc{
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    background: var(--main-color);
    border-radius: 4.5px;
}