.account-container{
    position: relative;
    width: calc(100%);
    min-height: 250px;
}

@media (max-width: 1370px) {
    .account-container{
        width: calc(100% - 45px);
    }
}

@media (max-width: 914px) {
    .account-container{
        width: 100%;
    }
}

.account-container .btn-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    margin-top: 20px;
    border-radius: 15px;
    background-color: var(--bg-color);
}

.account-container .btn-container .save-btn{
    height: 40px;
    width: 200px;
    border-radius: 15px;
    border: 0px;
    background-color: var(--main-color);
    cursor: pointer;
    font-family: AppFont;
    font-size: 24px;
}