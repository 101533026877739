html, body, #root{
    width: 100%;
    min-height: 100vh;
    height: auto;
}  

.main {
    min-height: 100vh;
    width: 100vw;
}

.right {
    min-height: 100vh;
    position: relative;
    background-image: url(../../src/Images/Heads/head-right.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.right .signup {
    height: clamp(40px, 8vmin, 72px);
    border-radius: 13px;
    border: 1px solid var(--main-color);
    background-color: transparent;
}

.right .signup span {
    position: relative;
    height: 19px;
    font-family: AppFontBold;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: var(--main-color);
}

.right .signin {
    height: clamp(40px, 8vmin, 72px);
    border-radius: 13px;
    border: 1px solid var(--main-color);
    background-color: var(--main-color);
}

.right .signin span {
    position: relative;
    height: 19px;
    font-family: AppFontBold;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: var(--fg-alt-color);
}

.right .separator {
    box-sizing: border-box;
}

.right .separator .separator1 {
    width: 100%;
    margin: auto 0;

    border-top: 2px solid #ACB6C5;
}

.right .separator .separator2 {
    width: 100%;
    margin: auto 0;
    border-top: 2px solid #ACB6C5;
}

.right .separator .text {
    position: relative;
    height: 29px;
    min-width: 76px;
    font-family: AppFont;
    font-size: 18px;
    line-height: 29px;
    /* identical to box height, or 161% */
    letter-spacing: -0.432px;
    color: #ACB6C5;
}

.right .add_card {
    height: clamp(40px, 8vmin, 72px);
    border-radius: 13px;
    border: 1px solid var(--main-color);
    background-color: transparent;
}

.right .add_card span {
    position: relative;
    height: 19px;
    font-family: AppFontBold;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height, or 106% */
    color: var(--main-color);
}

.store-container{
    width: clamp(250px, 39vw, 600px);
    height: 127px;
    margin-top: 200px;
    align-self: center;
}

.store-container .download-text{
    font-family: AppFontBold;
    font-size: 24px;
    line-height: 22px;
    /* identical to box height, or 92% */
    letter-spacing: -0.576px;
    color: var(--fg-color);
}


.store-container .power-text{
    font-family: AppFont;
    font-size: 18px;
    line-height: 29px;
    /* identical to box height, or 161% */
    letter-spacing: -0.432px;
    color: var(--fg-alt4-color);
    margin-bottom: 0px;
}

.store-container .icons-container{
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: clamp(30px, 10vw, 69px);
    display: flex;
    justify-content: space-between;
}

.store-container .icons-container img{
    width: 48%;
    margin: auto 0;
}