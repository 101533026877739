.phone-confermed-container .txt{
    font-family: AppFontBold;
    font-size: 24px;
    line-height: 22px;

    /* identical to box height, or 92% */
    text-align: center;
    letter-spacing: -0.576px;
    color: var(--fg-color);
    cursor: default;
}

@media (max-width: 760px) {
    .phone-confermed-container .txt{
        font-size: 20px;
    }
}

.phone-confermed-container{
    display: flex;
    flex-direction: column;
    width: 144px;
    height: 110px;
}

.checkmark{
    position: relative;
    width: 64px;
    height: 64px;
    margin: 0 auto;
}

.checkmark .line2{
    position: absolute;
    width: 7px;
    height: 18.81px;
    top:28px;
    left: 22px;
    border-radius: 5px;
    transform: rotate(-45deg);
    background-color: var(--bg-color);
}

.checkmark .line1{
    position: absolute;
    width: 7px;
    height: 30.81px;
    top:16px;
    left: 33px;
    border-radius: 5px;
    transform: rotate(30deg);
    background-color: var(--bg-color);
}

.checkmark .oval{
    width: 64px;
    height: 64px;
    border-radius: 1000px;
    background: #3EA6E8;
    z-index: -1;
}

.already-container-high{
    position: relative;
    width: 227px;
    height: 19px;
    left: 911px;
    top: 725px;
}